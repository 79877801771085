<template>
  <div class="content">
    <app-cells position="between">
      <app-cells position="start" :indent="false">
        <router-link
          :to="{ name: 'applications-list' }"
          class="btn btn--link"
        >
          <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.70917 13.7082C7.61629 13.8013 7.50595 13.8752 7.38447 13.9256C7.263 13.976 7.13277 14.002 7.00125 14.002C6.86973 14.002 6.7395 13.976 6.61802 13.9256C6.49655 13.8752 6.38621 13.8013 6.29332 13.7082L0.293962 7.70884C0.200846 7.61595 0.126968 7.50562 0.0765611 7.38414C0.0261537 7.26266 0.000206594 7.13243 0.000206606 7.00091C0.000206617 6.86939 0.0261537 6.73916 0.0765611 6.61769C0.126969 6.49621 0.200846 6.38587 0.293962 6.29299L6.29333 0.293626C6.48108 0.105873 6.73573 0.00039471 7.00125 0.000394734C7.26677 0.000394757 7.52142 0.105873 7.70918 0.293626C7.89693 0.481379 8.00241 0.736028 8.00241 1.00155C8.00241 1.26707 7.89693 1.52172 7.70917 1.70948L2.41574 7.00091L7.70917 12.2923C7.80229 12.3852 7.87617 12.4956 7.92658 12.617C7.97698 12.7385 8.00293 12.8688 8.00293 13.0003C8.00293 13.1318 7.97698 13.262 7.92658 13.3835C7.87617 13.505 7.80229 13.6153 7.70917 13.7082Z" fill="#2B93E7"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16 7.00108C16 7.26627 15.8947 7.5206 15.7071 7.70811C15.5196 7.89563 15.2653 8.00098 15.0001 8.00098L2.00149 8.00098C1.7363 8.00098 1.48197 7.89563 1.29445 7.70811C1.10694 7.5206 1.00159 7.26627 1.00159 7.00108C1.00159 6.73589 1.10694 6.48156 1.29445 6.29405C1.48197 6.10653 1.7363 6.00119 2.00149 6.00119L15.0001 6.00119C15.2653 6.00119 15.5196 6.10653 15.7071 6.29405C15.8947 6.48157 16 6.73589 16 7.00108Z" fill="#2B93E7"/>
          </svg>
        </router-link>
        <div class="title title--big title--theme">Заявка (ID: {{ $route.params.app }})</div>
      </app-cells>
      <button @click="onSidebarOpen" type="button" class="link">
        <img src="@/assets/img/applications/manage-icon.svg" alt="Member invite">
        <span>Управление заявкой</span>
      </button>
    </app-cells>
<!--    <div class="content__tabs">-->
<!--      <div class="tabs-list">-->
<!--        <ul class="tabs-list__body">-->
<!--          <li class="tabs-list__item">-->
<!--            <router-link :to="{ name: 'application-detail' }" exact-active-class="tabs-list__link&#45;&#45;active"-->
<!--                         class="tabs-list__link">Данные заявки-->
<!--            </router-link>-->
<!--          </li>-->
<!--          <li class="tabs-list__item">-->
<!--            <router-link :to="{ name: 'application-list' }" exact-active-class="tabs-list__link&#45;&#45;active"-->
<!--                         class="tabs-list__link">История заявок-->
<!--            </router-link>-->
<!--          </li>-->
<!--        </ul>-->
<!--      </div>-->
<!--    </div>-->
    <SidebarRight
      title="Управление заявкой"
      :class="{'sidebar-manage--full': show_sidebar}"
      @close-sidebar="show_sidebar = !show_sidebar"
    >
      <form class="sidebar-manage__form">
        <div class="form-group">
          <label for="reject_comment">Комментарий отказа</label>
          <div class="form-control">
            <textarea
              v-model="form.reject_comment"
              id="reject_comment"
              class="form-control__textarea"
              name="reject_comment"
              placeholder="Введите комментарий отказа"
            >
            </textarea>
          </div>
        </div>
        <div class="form-group">
          <label for="system_comment">Служебный комментарий</label>
          <div class="form-control">
            <textarea
              id="system_comment"
              class="form-control__textarea"
              name="system_comment"
              placeholder="Введите служебный комментарий"
              v-model="form.system_comment"
            >
            </textarea>
          </div>
        </div>
        <div class="form-group">
          <label>Статус заявки</label>
          <v-select
            v-model="form.status"
            :reduce="status => status.id"
            :options="status_options"
            label="name"
            :searchable="false"
            :clearable="false"
            placeholder="Выберите статус"
            class="select"
          >
            <template slot="open-indicator">
              <svg width="18" height="18" fill="none" class="open-indicator" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#2b93e7"></circle>
                <path
                  d="M8.6 11.82L5.16 8.05a.66.66 0 010-.87.53.53 0 01.8 0L9 10.52l3.04-3.34a.53.53 0 01.8 0c.21.24.21.63 0 .87L9.4 11.82A.53.53 0 019 12a.53.53 0 01-.4-.18z"
                  fill="#fff"></path>
              </svg>
            </template>
            <template slot="no-options">Выберите статус</template>
            <template slot="option" slot-scope="option">
              <div class="select__item d-center">{{ option.name }}</div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">{{ option.name }}</div>
            </template>
          </v-select>
        </div>
        <app-cells>
          <app-button @click="onSendManage" type="button">Сохранить</app-button>
        </app-cells>
      </form>
    </SidebarRight>
    <transition name="fade" mode="out-in">
      <router-view v-if="loaded" :app="app" />
    </transition>
  </div>
</template>

<script>
import SidebarRight from '@/components/SidebarRight'

export default {
  name: 'ApplicationIndex',
  components: {
    SidebarRight,
  },
  data() {
    return {
      app: {},
      form: {
        reject_comment: null,
        system_comment: null,
        status: null,
      },
      status_options: [],
      show_sidebar: false,
      loaded: false,
    }
  },
  created() {
    this.$store.dispatch('dict/GET_EVENT_APP_STATUS').then(response => { this.status_options = response.data })
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('events/application/GET_DATA', {
        event_id: this.$route.params.id,
        shift_id: this.$route.params.dir,
        app_id: this.$route.params.app
      })
        .then(response => {
          this.app = response.data
          this.form.status = response.data.status
          this.form.reject_comment = response.data.reject_comment
          this.form.system_comment = response.data.system_comment
          this.loaded = true
        })
    },
    onSidebarOpen() {
      this.show_sidebar = true
    },
    onSendManage() {
      if (this.form.status === 'rework' && !this.form.reject_comment) {
        this.$notify({
          type: 'warn',
          title: 'Внимание!',
          text: 'Напишите, пожалуйста, комментарий отказа'
        })
      } else {
        this.$store.dispatch('events/application/PATCH_DATA', {
          data: this.form,
          event_id: this.$route.params.id,
          shift_id: this.$route.params.dir,
          app_id: this.$route.params.app
        })
          .finally(() => { this.show_sidebar = false })
          .then(() => {
            this.getData()
            this.$notify({
              type: 'success',
              title: 'Успех!',
              text: 'Данные изменены'
            })
          })
      }
    },
  }
}
</script>
